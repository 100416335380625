$bodyBg: #f3f4fa;
$mainBg: #fff;
$mainColor: #08163c;
$txtColor: #4e4d4f;
$purple: #08163c;
$orange: #a9050d;
$white: #fff;
$black: #000;
$green: #4caf50;
$red: #a9050d;

$fontFamily: 'Roboto', sans-serif;

$fontSizeNormal: 1.125rem;
$fontSizeMedium: 1.25rem;
$fontSizeLarge: 1.5625rem;

$sideBarWidth: 384px;
$sideBarLogoHeight: 171px;
$spacing: 48px;
$mobileSpacing: 24px;
$borderRadius: 30px;

$mobile-width: 600px;
$tablet-width: 1366px;

@use '../../scss/abstracts/' as *;

.box {
  padding: 20px 30px;
  border-radius: $borderRadius;
  background-color: #f7f8ff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  &-purple {
    background-color: $purple;
    color: $white;
  }

  &-fullheight {
    height: calc(100% - $spacing);

    @include tablet {
      height: calc(100% - $mobileSpacing);
    }
  }
}

@use '../../scss/abstracts/' as *;

.main {
  padding-left: $sideBarWidth;
  overflow-x: hidden;

  @include tablet {
    padding-left: 0;
    width: 100vw;
  }

  &__content {
    min-height: 100vh;
    padding: $spacing;
    transition: all 0.5s ease-in-out;

    @include tablet {
      padding: $mobileSpacing;
      position: relative;
      background-color: $bodyBg;
      z-index: 100;
    }
  }
}

.sidebar-open .main__content {
  height: 100vh;
  overflow: hidden;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  transform: scale(0.8) translateX(50%);
  transition: all 0.5s ease-in-out;
}

.topnav {
  @include flex(center, space-between);
  margin-top: 20px;
  margin-bottom: $spacing;

  @include tablet {
    margin-bottom: $mobileSpacing;
  }
}

.sidebar-toggle {
  --size: 40px;
  width: var(--size);
  height: var(--size);
  @include flex(center, center);
  cursor: pointer;
  display: none;

  @include tablet {
    display: flex;
  }

  i {
    font-size: 3rem;
    color: $mainColor;
  }
}

.user-info {
  @include flex(center, flex-start);
  background-color: #f3f4fa !important;
  border-radius: $borderRadius;
  height: 80px !important;
  width: max-content;

  &__img {
    --size: 90px;
    width: 90px;
    height: 90px;
    border-radius: $borderRadius;

    img {
      border-radius: $borderRadius;
    }
  }

  &__name {
    padding: 0 $spacing;
    font-size: $fontSizeMedium;
    font-weight: 700;

    @include tablet {
      padding: 0 $mobileSpacing;
    }
  }
}

.user-info__img {
  width: 200px !important;
  height: auto !important;
  margin: 12px !important;
  padding: 6px !important;
  z-index: 2 !important;
}

.user-info img {
  width: 200px !important;
  margin: 15px !important;
  padding-left: 12px !important;
  z-index: 20 !important;
}

.homepageBoard {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.titleDiv {
  margin: auto;
  width: 30%;
  padding: 10px;
  flex-direction: row !important;
  display: flex;
}

.titleDiv img {
  width: 60px;
}

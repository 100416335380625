@use '../../scss/abstracts/' as *;

.summary-box {
  @include flex(stretch, flex-start);

  &__chart {
    width: 40%;

    &__value {
      margin-top: -30%;
      font-weight: 700;
    }
  }

  &__info {
    width: 60%;
    @include flex(flex-start, space-between);
    flex-direction: column;

    &__title {
      font-size: $fontSizeMedium;
      font-weight: 700;
      line-height: 1.2;

      span {
        font-size: 13px;
        font-weight: 400;
      }
    }

    &__value {
      font-weight: 700;
      font-size: $fontSizeLarge;
      color: $black;
    }
  }
}

.summary-box-special {
  @include flex(center, space-between);
  flex-direction: column;
  height: 100%;

  &__title {
    font-weight: 700;
    font-size: $fontSizeMedium;
  }

  &__value {
    font-size: $fontSizeLarge;
    font-weight: 700;
  }
}

.disclainerContainer {
  width: 100%;
  max-width: 100%;
  height: 260px;
  overflow: hidden;
  margin-bottom: 20px;
}

.disclainerContainer:hover {
  overflow-y: scroll;
}

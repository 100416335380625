.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.green_btn {
  border: none;
  outline: none;
  padding: 12px 0;
  color: #ffffff;
  background-color: #34eba8;
  border-radius: 6px;
  width: 180px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.letterhead_image {
  width: 100%;
}
.success_image {
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 5%;
}

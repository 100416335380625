.signup-div {
  height: 380px;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 8px;
}

.signup-div:hover {
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.disclainerContainer {
  margin: 8px;
}
